import {mapActions, mapGetters, mapMutations} from "vuex";
import {VueSlideToggle} from "vue-slide-toggle";
export default {
  name: "contact-item",
  props: {
    data: {
      type: Object,
      default: ()=> {}
    }
  },
  components:{
    VueSlideToggle
  },
  data(){
    return{
      open: false,
    }
  },
  created() {
  },
  computed:{
  },

  methods:{
    addEvent() {
      window.dataLayer = window.dataLayer || [];
      let obj = {
        event: "generate_lead",
        lead: {
          source: window.location.href
        }
      }
      window.dataLayer.push(obj);
    }
  }
}